<template>
  <div class="card">

    <div class="page-header page-header-light" >
      <div class="page-header-content header-elements-lg-inline" >
        <div class="page-title d-flex" style="padding: 9px;padding-left: 0px;">
          <h6>
            <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Status  </span>  Report
          </h6>
          <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
        </div>

        <div class="header-elements d-none">
          <form action="#">
            <div class="form-group">

              <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                <div class="form-control-feedback">
                  <i class="icon-search4"></i>
                </div>

                <div class="input-group-append position-static">
                  <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
                    <i class="icon-gear"></i>
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div class="table-responsive">


      <table id="status_report"
             class="table table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="item.code" data-width="75" data-sortable="true">Code</th>
          <th data-field="item.group.hsn" data-width="75" data-sortable="true">HSN</th>
          <th data-field="item.group.name" data-sortable="true">Article</th>
<!--          <th data-field="item.design.name" data-sortable="true">Design</th>-->
          <th data-field="item.color.name" data-sortable="true">Color</th>
          <th data-field="item.quality.name" data-sortable="true">Quality</th>
<!--          <th data-field="item.style.name" data-sortable="true">Style</th>-->
<!--          <th data-field="item.size.name" data-sortable="true">Size</th>-->
          <th data-field="stock" data-width="100" data-formatter="weightFormat" data-align="right" data-sortable="true" >Qty</th>
          <th data-field="stock_wgt" data-width="100" data-formatter="weightFormat" data-align="right" data-sortable="true">Weight</th>
          <th data-field="taxable_amt" data-width="100" data-formatter="indianFormat" data-align="right" data-sortable="true">Value</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: darkgreen;"> <i class="icon-file-excel" ></i>Export</a>
      </div>
    </div>
    <!-- End of Context Menu -->



  </div>

</template>

<script>
  import InvoiceForm from '@/views/tms/vouchers/invoice/InvoiceForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'


  export default {
    name: 'StatusView',
    components: {
      InvoiceForm,
      FabButton,
    },
    store,
    data () {
      return {
        mytable: {},
        invoice: JSON.parse('{"code":0,"ver":0,"status":0,"is_cr":"","type":0,"finyear":0,"series":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","customer":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_bil_no":"","eway_bil_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
        series: 302,
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#status_report');
      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();
          if ($(e.target).text() === 'Modify') {
            self.modifyDocument(id);
          }else if ($(e.target).text() === 'Delete') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#status_report>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadData();
    },
    methods: {
      closeModal() {
        this.$modal.hide('invoice-window');
      },
      beforeOpen(){

      },
      beforeClose(){
        this.loadData();
      },
      showModal () {
        this.$modal.show('invoice-window');
      },
      loadData () {
        let self = this;
        $('#txtsearch').val('');

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);


        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/stock/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if( _.isArray( resp.data )){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        });

      }
    }

  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
